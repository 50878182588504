import React from "react";
import {
  SubLayout,
  ClinicsParticle,
  CareerParticle,
  ContactParticle,
  Services,
} from "../components";
import Seo from "../components/Seo";
// import Seo from "../components/seo";

const ServicesPage = () => {
  return (
    <SubLayout>
      <Seo
        title="診療内容"
        description="医療法人社団慶育会で提供している診療内容を見ることができます。"
        keywords="医療法人社団 慶育会,グレースメディカルグループ,在宅医療,訪問診療,訪問医療,美容皮膚科,診療内容"
      />
      <Services />
      <ClinicsParticle />
      <CareerParticle />
      <ContactParticle />
    </SubLayout>
  );
};

export default ServicesPage;
